// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
// import createCallChannel from '../channels/data_channel'

import { Modal, Tooltip } from 'bootstrap'

import ApexCharts from 'apexcharts'

import { createChart } from 'lightweight-charts'

import ClipboardJS from 'clipboard'

import '../search'
const clipboard = new ClipboardJS('.btn')

clipboard.on('success', function (e) {
  var previous = e.trigger.innerHTML
  e.trigger.innerHTML = 'Copied Indicator Script!'
  setTimeout(function () {e.trigger.innerHTML = previous}, 2000)

})

Turbo.session.drive = false
Turbo.start()
window.ApexCharts = ApexCharts
window.createChart = createChart

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

console.log('Optionomics:', 'support@optionomics.ai')
